import * as React from "react";
import "./assets/styles.css";

const navbarStyle = {};
const liStyle = {};
const NavBar = () => {
  return (
    <div>
      <ul style={navbarStyle}>
        <li style={liStyle}>
          <a href="/">Home</a>
        </li>
        <li style={liStyle}>
          <a href="/tech">Technology</a>
        </li>
        <li style={liStyle}>
          <a href="/hologram">Holograms In Black!</a>
        </li>
        <li style={liStyle}>
          <a href="/facecloak">FaceCloak</a>
        </li>
        <li style={liStyle}>
          <a href="/magni-pi">Magni-Pi</a>
        </li>
        <li style={liStyle}>
          <a href="/democontent">Demo Content</a>
        </li>
        <li style={liStyle}>
          <a href="/contact">Contact</a>
        </li>
      </ul>
    </div>
  );
};
export default NavBar;

import * as React from "react";
import topbanner from "./assets/AmazonGiftCardBanner.001.png";
import NavBar from "./navbar";

// import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router'
import "./assets/styles.css";

// styles
const imgStyle = {
  color: "#232129",
  padding: 10,
  width: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const footerStyle = {
  textAlign: "center",
  color: "#716969",
  fontSize: 10,
};

const pageStyles = {
  color: "#232129",
  backgroundColor: "#ffffff",
  padding: 0,
  // height: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Cogni-Trax</title>
      <NavBar />

      <img src={topbanner} alt="" style={imgStyle} />

      <iframe
        title="contact"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfeZCne1Izwn6j0JqFEgQZqBVTzhFacrMH862I0k_WzdP4wsg/viewform?embedded=true"
        width="100%"
        height="943"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <div id="footer" style={footerStyle}>
        <h3> ceo@cogni-trax.com </h3>
        <p className="copyright">&copy; Cogni-Trax </p>
      </div>
    </main>
  );
};

export default IndexPage;
